import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Dielectric etching tools`}</h4>
    <p>{`The main types of dry etching tools are used either for etching conductors or dielectrics. Major subtypes of conductor and dielectric dry etching tools include sputter etching and plasma etching; plasma etching is further divided into reactive ion etching and deep reactive ion etching (a form of reactive ion etching used to make deep wells). The most commonly used form is reactive ion etching.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      